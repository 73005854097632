export function processResults(document) {
  const entityContext = {
    lineIdentifier: null
  };

  return document.listPages().map((page) => {
    page.form._fields = processFormFields(page.form.listFields());

    page.listTables().forEach((table) => processTable(table, entityContext));

    return page;
  });
}

function isNumericValue(value) {
  return /^\s*\w/.test(value) === false;
}

function normalizeMoney(value) {
  if (!isNumericValue(value)) {
    return value;
  }

  const match = value.match(/[0-9,]+(?:\.[0-9]*)?/);

  if (match) {
    return match[0];
  } else {
    return "";
  }
}

function processFormFields(fields) {
  const newFields = [];
  
  fields.filter(usefulField).forEach((field) => {
    let subfields = splitSubfields(field.key.text, field.value.text);
    if (Object.keys(subfields).length === 0) {
      subfields = {[field.key.text]: field.value.text};
    }

    Object.entries(subfields).forEach(([subfield, subfieldValue]) => {
      subfieldValue = stripLeadingAsterisks(subfieldValue);
      subfieldValue = normalizeMoney(subfieldValue);
  
      newFields.push({
        key: {
          text: subfield,
          geometry: field.key.geometry,
        },
        value: {
          text: subfieldValue,
          geometry: field.value.geometry,
        }
      });
    });
  });

  return newFields;
}

function processTable(table, entityContext) {
  table.listRows().forEach((row) => {
    row.listCells().forEach((cell) => {
      if (cell.hasEntityTypes(["TABLE_TITLE", "TABLE_SECTION_TITLE", "COLUMN_HEADER", "TABLE_SUMMARY"])) {
        const lineIdentifierMatch = cell.text.match(/^\s*(LINE \d+)\s/);

        if (lineIdentifierMatch) {
          entityContext.lineIdentifier = lineIdentifierMatch[1];
          // console.log(`Set line ID: ${entityContext.lineIdentifier}`);
        } 
      }

      if (entityContext.lineIdentifier && 
          /^\s*[A-Z][A-Z]?\s/.test(cell.text) &&
          cell.text.toUpperCase() === cell.text) {
        // console.log(`Prefixing ${cell.text} with ${entityContext.lineIdentifier}`);
        Object.defineProperty(
          cell, 
          "text", 
          {
            value: `${entityContext.lineIdentifier} - ${cell.text}`, 
            writable: true
          }
        );
      }
    });
  });
}

function splitSubfields(field, value) {
  const subfields = {};

  Object.entries(subfieldConfig()).forEach(([subfield, parts]) => {
    if (field.trim() === subfield) {
      const matches = value.match(/[0-9,]+(?:\.[0-9]*)?\s*%?/g) || [];

      parts.forEach((part, i) => {
        subfields[`${field} - ${part}`] = matches[i] || "";
      });
    }
  });

  return subfields;
}

function stripLeadingAsterisks(value) {
  return value.replace(/^\*+/, '').trim();
}

function subfieldConfig() {
  return {
    "13 Other deductions": ["A", "B"],
    "14 Self-employment earnings (loss)": ["A", "C"],
    "15 Credits": ["C"],
    "17 Alternative minimum tax (AMT) items": ["A", "B"],
    "18 Tax-exempt income and nondeductible expenses": ["A", "B", "C"],
    "19 Distributions": ["A"],
    "20 Other information": ["A", "B", "D"],
    "Profit": ["Beginning", "Ending"],
    "Capital": ["Beginning", "Ending"],
    "Loss": ["Beginning", "Ending"],
    "Nonrecourse": ["Beginning", "Ending"],
    "Qualified nonrecourse financing": ["Beginning", "Ending"],
    "Recourse": ["Beginning", "Ending"]
  };
}

function usefulField(field) {
  return !["OMB No.", "Schedule", "(Form"].includes(field.key.text);
}

export default [
  [
    "form",
    ...(new Array(13).fill("table")),
    "form",
    ...(new Array(20).fill("table")),
    "form",
    "table",
    "form",
    "table",
    "form",    
    "table",
    "form",       
    ...(new Array(17).fill("table")),
    "form",
    "table",
    "table",
    "form",
    ...(new Array(7).fill("table")),
    "form",
    ...(new Array(12).fill("table")),
    ...(new Array(5).fill("form")),
    ...(new Array(4).fill("table")),
  ],
  [
    "form",
    ...(new Array(17).fill("table")),
  ],  
  [
    "form",
    ...(new Array(2).fill("table")),
    "form",
    "table",
    "form",
    ...(new Array(2).fill("table")),
    "form",
    ...(new Array(2).fill("table")),
    "form",
    "form",
    ...(new Array(3).fill("table")),
    "form",
    ...(new Array(6).fill("table")),
    "form",
    ...(new Array(6).fill("table")),
    "form",
    "form",
    "table",
    "form",
    ...(new Array(28).fill("table")),
    "form",
    ...(new Array(2).fill("table")),
    "form",
    "table",
    "form",
    ...(new Array(2).fill("table")),
    "form",
    ...(new Array(2).fill("table")),
    "form",
    "form",
    ...(new Array(3).fill("table")),
    "form",
    ...(new Array(6).fill("table")),
    "form",
    ...(new Array(6).fill("table")),
    "form",
    "form",
    "table",
    "form",
    ...(new Array(28).fill("table")),
    "form",
    ...(new Array(2).fill("table")),
    "form",
    "table",
    "form",
    ...(new Array(2).fill("table")),
    "form",
    ...(new Array(2).fill("table")),
    "form",
    "form",
    ...(new Array(10).fill("table")),
    "form",
    ...(new Array(6).fill("table")),
    "form",
    "form",
    "table",
  ],  
  [
    "form",
    ...(new Array(2).fill("table")),
    "form",
    "table",
    "form",
    "form",
    "form",
    ...(new Array(5).fill("table")),
    "form",
    ...(new Array(2).fill("table")),
    "form",
    "table",
    "form",
    "form",
    "form",
    ...(new Array(5).fill("table")),
  ],  
  [
    "table",
  ],
  [
    "table",
  ],  
  [
    "table",
  ],  
  [
    "table",
  ],  
  [
    "table",
  ],  
  [
    "table",
  ],  
  [
    "table",
  ],  
  [
    ...(new Array(4).fill("table")),
  ],    
  [
    ...(new Array(2).fill("form")),
  ],   
  [
    ...(new Array(3).fill("form")),
  ],   
  [
    ...(new Array(6).fill("form")),
  ],     
  [
    "form",
  ], 
  [
    ["form", "table"],
  ],             
];
import { useCallback, useEffect, useState, useRef } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './App.css';
import '@fontsource/roboto/400.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ApiTableCellEntityType, TextractDocument } from "amazon-textract-response-parser";
import Papa from 'papaparse';
import { processResults } from "./postprocess.js";
import pageLabels from "./page_labeling.js";

import response1Json_1 from "./textract_responses/doc1/response_1.json";
import response1Json_2 from "./textract_responses/doc1/response_2.json";
import response1Json_3 from "./textract_responses/doc1/response_3.json";
import response1Json_4 from "./textract_responses/doc1/response_4.json";
import response1Json_5 from "./textract_responses/doc1/response_5.json";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const resizeObserverOptions = {};

const maxWidth = 1024;
const maxHeight = 2048;

export default function App() {
  const [file, setFile] = useState("https://guru-dist.s3.us-west-2.amazonaws.com/insurance-quote-demo/insurance_quote_1.pdf");
  const [numPages, setNumPages] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [currentPageLabels, setCurrentPageLabels] = useState(pageLabels[0]);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState(maxWidth);
  const [containerHeight, setContainerHeight] = useState(maxHeight);
  const [documentIndex, setDocumentIndex] = useState(0);
  const [processedDocument, setProcessedDocument] = useState(null);
  const [document1, setDocument1] = useState(null);
  const [textractResponse, setTextractResponse] = useState(null);
  const [page, setPage] = useState(null);
  const pageCanvasRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    const document1 = new TextractDocument([
      response1Json_1,
      response1Json_2,
      response1Json_3,
      response1Json_4,
      response1Json_5,
    ]);

    setDocument1(document1);
    setTextractResponse(document1);
    postProcess(document1);
    setPage(document1.pageNumber(pageNum + 1));
  }, []);

  const drawBoundingBox = (context, box) => {
    context.clearRect(
      box.left * pageCanvasRef.current.width, 
      box.top * pageCanvasRef.current.height, 
      box.width * pageCanvasRef.current.width, 
      box.height * pageCanvasRef.current.height,
    ); 
  };  

  const downloadCsv = () => {
    const csv = [];
    for (let pageIndex = 0; pageIndex < numPages; ++pageIndex) {
      const nextPage = textractResponse.pageNumber(pageIndex + 1);

      if (pageIndex === 0) {
        nextPage.form.listFields().forEach((field, index) => {
          csv.push([field.key.text, field.value.text]);
        });
        csv.push([]);
      }
      else {
        for (let tableIndex = 0; tableIndex < nextPage.nTables; ++tableIndex) {
          const table = nextPage.tableAtIndex(tableIndex);

          table.listRows().forEach((row) => {
            csv.push(row.listCells().map((cell) => cell.text));
          });
          csv.push([]);
        }
      }
    }

    const csvString = Papa.unparse(csv);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "data.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(Math.min(entry.contentRect.width, maxWidth));
      setContainerHeight(Math.min(entry.contentRect.height, maxHeight));
    }
  }, []);
  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const pdfChanged = (event) => {
    setDocumentIndex(event.target.value);

    let newDocument = null;
    if (event.target.value === 0) {
      newDocument = document1;
      setFile("https://guru-dist.s3.us-west-2.amazonaws.com/insurance-quote-demo/insurance_quote_1.pdf");
    }

    setTextractResponse(newDocument);
    setPage(newDocument.pageNumber(1));
    postProcess(newDocument);    
    setCurrentPageLabels(pageLabels[event.target.value]);        
    setPageNum(0);
  }

  const formRowHovered = (field) => {
    if (overlayRef.current) {
      const context = prepareOverlayCanvas();
      drawBoundingBox(context, field.key.geometry.boundingBox);
      drawBoundingBox(context, field.value.geometry.boundingBox);
    }
  };

  const postProcess = (document) => {
    setProcessedDocument(processResults(document));
  };

  const prepareOverlayCanvas = () => {
    if (overlayRef.current) {
      const context = overlayRef.current.getContext('2d');
      context.fillStyle = 'rgba(0, 0, 0, 0.75)';
      context.fillRect(
        0,
        0,
        pageCanvasRef.current.width,
        pageCanvasRef.current.height,
      );

      return context;
    }
  };

  const rowUnhovered = () => {
    if (overlayRef.current) {
      const context = overlayRef.current.getContext('2d');
      context.clearRect(0, 0, pageCanvasRef.current.width, pageCanvasRef.current.height);
    }
  };  

  const tableRowHovered = (row) => {
    const context = prepareOverlayCanvas();
    row.listCells().forEach((cell) => drawBoundingBox(context, cell.geometry.boundingBox));
  };  

  const updatePageNum = (newPageNum) => {
    newPageNum = Math.min(Math.max(0, newPageNum), numPages - 1);
    setPageNum(newPageNum);

    setPage(textractResponse.pageNumber(newPageNum + 1));
  };

  return (
    <div className="Example">
      <header style={{backgroundColor: "#6131FF"}}>
        <h1>Guru PDF Interpretation</h1>
      </header>
      <div className="Example__container">
        <Grid container>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ButtonGroup variant="contained">
                <Button style={{backgroundColor: "#6131FF"}} onClick={() => updatePageNum(pageNum - 1)}>{"<"}</Button>
                <Button style={{backgroundColor: "#6131FF"}}>Page {pageNum + 1}</Button>
                <Button style={{backgroundColor: "#6131FF"}} onClick={() => updatePageNum(pageNum + 1)}>{">"}</Button>
              </ButtonGroup>                           
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <FormControl fullWidth size="small">
                <Select style={{backgroundColor: "#6131FF", color: "white"}} value={documentIndex} onChange={pdfChanged}>
                  <MenuItem value={0}>Example 1</MenuItem>
                </Select>
              </FormControl>
            </Box>          
          </Grid>          
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Button style={{backgroundColor: "#6131FF", color: "white"}} onClick={downloadCsv} variant="contained">Download</Button>
            </Box>          
          </Grid>
          <Grid item xs={6} sx={{height: `${containerHeight}px`}}>
            <div className="Example__container__document" ref={setContainerRef} style={{position: "relative"}}>
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                <Page pageNumber={pageNum + 1}
                      width={containerWidth}
                      canvasRef={pageCanvasRef}>
                  <canvas ref={overlayRef} 
                          width={pageCanvasRef.current ? pageCanvasRef.current.width : 1} 
                          height={pageCanvasRef.current ? pageCanvasRef.current.height : 1} 
                          style={{
                            position: "absolute", 
                            top: 0, 
                            left: 0,
                          }}/>
                </Page>
              </Document>
            </div>                       
          </Grid>
          {
            processedDocument && <Grid item xs={6} key={`document-table-${documentIndex}`} pt={1} style={{height: `${containerHeight}px`, overflow: "auto"}}>
              {
                currentPageLabels[pageNum].includes("form") && <>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead style={{backgroundColor: "#6131FF", color: "white"}}>
                        <TableRow>
                          <TableCell style={{color: "white"}}>Field</TableCell>
                          <TableCell style={{color: "white"}}>Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {processedDocument[pageNum].form.listFields().map((field, index) => (
                          <TableRow key={`${field}}-${index}`} onMouseEnter={() => formRowHovered(field)} onMouseLeave={rowUnhovered}>
                            <TableCell component="th" scope="row">
                              <strong>{field.key.text}</strong>
                            </TableCell>
                            <TableCell>
                              {field.value.text}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>                
                </>
              }
              {
                currentPageLabels[pageNum].includes("table") &&
                  [...Array(page.nTables).keys()].map((_, tableIndex) => {
                    const table = page.tableAtIndex(tableIndex);
                    return <TableContainer sx={{mt: 2}} key={`table-${tableIndex}`} component={Paper}>
                      <Table size="small">
                        <TableBody>
                          {table.listRows().map((row, rowIndex) => (
                            <TableRow key={`row-${rowIndex}`} rowSpan={row.rowSpan} onMouseEnter={() => tableRowHovered(row)} onMouseLeave={rowUnhovered}>
                              {
                                row.listCells().map((cell, cellIndex) => {
                                  let style = {};
                                  if (cell.hasEntityTypes(ApiTableCellEntityType.Title) || cell.hasEntityTypes(ApiTableCellEntityType.Footer)) {
                                    style = {backgroundColor: "#6131FF", color: "white", textAlign: "center"};
                                  }
                                  else if (cell.hasEntityTypes(ApiTableCellEntityType.SectionTitle) || cell.hasEntityTypes(ApiTableCellEntityType.Summary)) {
                                    style = {backgroundColor: "#5decc9"};
                                  }
                                  else if (cell.hasEntityTypes(ApiTableCellEntityType.ColumnHeader)) {
                                    style = {fontWeight: "bold"};
                                  }                                    
                                  
                                  return <TableCell key={`cell-${cellIndex}`} colSpan={cell.columnSpan} style={style}>
                                    {cell.text}
                                  </TableCell>;
                                })
                              }
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>;
                  })
              }
            </Grid>
          }          
        </Grid>
      </div>
    </div>
  );
}
